<template>
  <div class="col-12 col-md-8 mx-auto">
    <div class="row">
      <div class="col">
        <h1>Add Phone Number</h1>
      </div>
    </div>
    <div class="card theme-card">
      <div class="card-body">
        <input-control v-model="number" type="horizontal" :cleave="cleave.phone"
          >Phone Number</input-control
        >
        <select-control
          v-model="type"
          :options="types"
          labelClass="col-12 col-md-2"
          >Phone Type</select-control
        >
        <save :saving="Profile.isSaving" @click="addNumber"
          >Save Phone Number</save
        >
        <button
          v-if="src"
          class="btn btn-link ml-2"
          @click.prevent="navigateBack"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AdminAddPhone",
  computed: {
    ...mapState(["Profile"]),
    ...mapState({
      user: (state) => state.auth.user,
    }),
    src: function () {
      return this.$route.query.src;
    },
  },
  mounted() {
    if (!this.user) {
      this.$router.back();
    }
  },
  data() {
    return {
      number: null,
      cleave: {
        phone: { blocks: [3, 3, 4], delimiter: "-", numericOnly: true },
      },
      type: "CELL",
      types: [
        { value: "HOME", label: "Home Phone" },
        { value: "CELL", label: "Cell Phone" },
        { value: "WORK", label: "Work Phone" },
        { value: "FAX", label: "Fax" },
      ],
    };
  },
  methods: {
    addNumber: function () {
      let data = {
        user_id: this.user.id,
        number: this.number,
        type: this.type,
        status: "ACTIVE",
      };
      this.$store.dispatch("Profile/createPhone", data);
    },
    navigateBack: function () {
      this.$router.go(-1);
    },
  },
};
</script>
